<script lang="ts">
    import * as Dialog from '$lib/components/ui/dialog';
    import Button from '$lib/components/ui/button/button.svelte';
    import { createEventDispatcher } from 'svelte';
    import toast from 'svelte-french-toast';

    import NoSymbolIcon from '@/Icons/NoSymbolIcon.svelte';
    import FlagIcon from '@/Icons/FlagIcon.svelte';

    export let show: boolean = false;
    export let isUserBlocked: boolean;
    export let user: any;
    export let lists: { id: string }[] = [];
    export let reportTypes: string[] = [];
    export let app: { baseUrl: string } = { baseUrl: '' };

    const dispatch = createEventDispatcher<{ blockStateChanged: { isUserBlocked: boolean } }>();

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') || '' : '';

    async function handleBlock() {
        try {
            const type = isUserBlocked ? 'remove' : 'add';
            const data = new URLSearchParams();
            data.append('list_id', lists[1].id);
            data.append('user_id', user.id);
            data.append('returnData', 'true');

            const requestMethod = type === 'add' ? 'POST' : 'DELETE';
            const requestUrl =
                type === 'add' ? `${app.baseUrl}/my/lists/members/save` : `${app.baseUrl}/my/lists/members/delete`;

            console.log('Making block request:', { requestUrl, requestMethod, data: Object.fromEntries(data) });

            const response = await fetch(requestUrl, {
                method: requestMethod,
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data,
                credentials: 'same-origin',
            });

            const result = await response.json();
            console.log('Block response:', result);

            if (!response.ok) {
                throw new Error(result.message || result.errors?.[0] || 'Server returned an error');
            }

            isUserBlocked = !isUserBlocked;

            if (result.message) {
                toast.success(result.message);
            } else {
                toast.success(isUserBlocked ? 'Successfully blocked user' : 'Successfully unblocked user');
            }

            show = false;

            dispatch('blockStateChanged', { isUserBlocked });
        } catch (error) {
            console.error('Block action error:', error);
            const errorMessage = error.responseJSON?.message || error.message || 'An error occurred';
            toast.error(errorMessage);
        }
    }

    let showReportModal: boolean = false;
    let reportReason: string = reportTypes[0];
    let reportDetails: string = '';

    function handleReport() {
        showReportModal = true;
        show = false;
    }

    async function submitReport() {
        try {
            const data = new URLSearchParams();
            data.append('user_id', user.id);
            data.append('type', reportReason);
            data.append('details', reportDetails);

            const response = await fetch(`${app.baseUrl}/report/content`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data,
                credentials: 'same-origin',
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.message || result.errors?.[0] || 'Server returned an error');
            }

            toast.success('Report submitted');
            showReportModal = false;
            reportDetails = '';
        } catch (error) {
            console.error('Report submission error:', error);
            const errorMessage = error.responseJSON?.message || error.message || 'Failed to submit report';
            toast.error(errorMessage);
        }
    }
</script>

<!-- Options Modal -->
<Dialog.Root bind:open="{show}">
    <Dialog.Content>
        <Dialog.Header>
            <Dialog.Title>Options</Dialog.Title>
        </Dialog.Header>
        <div class="space-y-4">
            <button class="flex w-full items-center rounded-lg p-2 hover:bg-gray-100" on:click="{handleBlock}">
                <NoSymbolIcon />
                <span class="ml-3">{isUserBlocked ? 'Unblock' : 'Block'}</span>
            </button>
            <button class="flex w-full items-center rounded-lg p-2 hover:bg-gray-100" on:click="{handleReport}">
                <FlagIcon />
                <span class="ml-3">Report</span>
            </button>
        </div>
    </Dialog.Content>
</Dialog.Root>

<!-- Report Modal -->
<Dialog.Root bind:open="{showReportModal}">
    <Dialog.Content>
        <Dialog.Header>
            <Dialog.Title>Report user or post</Dialog.Title>
        </Dialog.Header>
        <div class="space-y-4">
            <div class="space-y-2">
                <label for="reasonSelect" class="block text-sm font-medium">Reason</label>
                <select id="reasonSelect" class="w-full rounded-md border p-2" bind:value="{reportReason}">
                    {#each reportTypes as type}
                        <option value="{type}">{type}</option>
                    {/each}
                </select>
            </div>

            <div class="space-y-2">
                <label for="details" class="block text-sm font-medium">Details</label>
                <textarea id="details" class="w-full rounded-md border p-2" rows="2" bind:value="{reportDetails}"
                ></textarea>
            </div>
        </div>
        <Dialog.Footer>
            <Button class="rounded px-4 py-2 text-white" on:click="{submitReport}">Report</Button>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>
